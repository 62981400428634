import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../reducers/rootReducer";
import { useFetch } from "../../helpers/loading";
import { endpoints } from "../../serverDetails";
//import { CalendarDatePicker } from "../FormComponents/CalendarDatePicker/CalendarDatePicker";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Spinner from "react-spinkit";
import axios from "api";
import { CalendarDatePickerField } from "../FormComponents/CalendarDatePicker/CalendarDatePicker";
import { dateObjectToString } from "../../helpers/dates";

const errorResults = "/content/images/Icon_Error.png";

export interface UsersUpdatePermissions {
  class?: string;
}

const UsersUpdatePermissions = (props: UsersUpdatePermissions) => {
  const dispatch = useDispatch();

  // Update formatting for prettier

  const handleToast = (message: string) => {
    dispatch({ type: "SHOW_TOAST", message: message });
    window.setTimeout(() => {
      dispatch({ type: "HIDE_TOAST" });
    }, 3000);
  };

  const tableState = useSelector((state: AppState) => state.tableReducer);

  const requestConfig = React.useMemo(() => {
    return { params: { Id: tableState.selectedUsersPageIds[0] } };
    //eslint-disable-next-line
  }, []);

  const handleSubmit = async (
    payload: any,
    setFieldError: (field: string, val: string) => void,
    setSubmitting: any
  ) => {
    if (payload) {
      let permissionValues = Object.keys(payload);

      let postObject = {
        id: requestConfig.params.Id,
        b_access_all_farms: payload.b_access_all_farms,
        permissions: payload.b_access_all_farms
          ? []
          : //eslint-disable-next-line
            payload.permissions
              .map((p: any) => {
                let touched = permissionValues.find(v => v === `farm ${p.id}`);
                if (touched && payload[touched]) {
                  let fieldAccess = permissionValues.find(
                    v => v === `b_access_all_fields ${p.id}`
                  );
                  return {
                    id: p.id,
                    b_access_all_fields:
                      fieldAccess && payload[fieldAccess] ? true : false,
                    fields:
                      fieldAccess && payload[fieldAccess]
                        ? []
                        : //eslint-disable-next-line
                          p.fields
                            .map((f: any) => {
                              let fieldTouched = permissionValues.find(
                                v => v === `field ${f.id}`
                              );
                              if (fieldTouched && payload[fieldTouched]) {
                                let pointAccess = permissionValues.find(
                                  v => v === `b_access_all_scout_points ${f.id}`
                                );
                                return {
                                  id: f.id,
                                  b_access_all_scout_points:
                                    pointAccess && payload[pointAccess]
                                      ? true
                                      : false,
                                  scout_points:
                                    pointAccess && payload[pointAccess]
                                      ? []
                                      : //eslint-disable-next-line
                                        f.scout_points
                                          .map((s: any) => {
                                            let scoutAccess = permissionValues.find(
                                              v => v === `point ${s.id}`
                                            );
                                            if (
                                              scoutAccess &&
                                              payload[scoutAccess]
                                            ) {
                                              let scoutTouched = permissionValues.find(
                                                v => v === `point share ${s.id}`
                                              );
                                              if (
                                                scoutTouched &&
                                                payload[scoutTouched]
                                              ) {
                                                let pointDate = permissionValues.find(
                                                  v =>
                                                    v === `point date ${s.id}`
                                                );
                                                return {
                                                  id: s.id,
                                                  scout_date: pointDate
                                                    ? dateObjectToString(
                                                        payload[pointDate]
                                                      )
                                                    : dateObjectToString(
                                                        new Date()
                                                      ),
                                                };
                                              } else {
                                                return {
                                                  id: s.id,
                                                  scout_date: "",
                                                };
                                              }
                                            }
                                          })
                                          .filter((i: any) => i),
                                };
                              }
                            })
                            .filter((i: any) => i),
                  };
                }
              })
              .filter((i: any) => i),
      };

      if (
        postObject.permissions.length === 0 &&
        !postObject.b_access_all_farms
      ) {
        setFieldError("permissions", "Please select some permissions");
        setSubmitting(false);
      } else {
        setSubmitting(true);
        axios
          .post(endpoints.users.updatePermission, postObject)
          .then(res => {
            if (res.data.status === "1") {
              setSubmitting(false);
              dispatch({ type: "CLOSE_RIGHT_POP_OUT" });
              handleToast("Permissions successfully updated");
              dispatch({
                type: "REFRESH_TABLE",
              });
            }
          })
          .catch(err => {
            if (
              err.response &&
              err.response.data &&
              err.response.data.errors.length > 0
            ) {
              setFieldError(
                err.response.data.errors[0].field_name,
                err.response.data.errors[0].message_code
              );
              setSubmitting(false);
            }
          });
      }
    }
  };

  const { data, loading, error } = useFetch(
    endpoints.users.updatePermissionDetails,
    requestConfig
  );

  if (loading) {
    return (
      <span>
        <Spinner name="three-bounce" fadeIn="none" />
      </span>
    );
  }

  if (error)
    return (
      <div className="ErrorResults">
        <div className="NoResults">
          <img src={errorResults} alt={"Error"} />
          <p>Error</p>
        </div>
      </div>
    );

  if (data) {
    let p: { [k: string]: any } = {};

    p.permissions = data.details.permissions;

    if (data.details.b_access_all_farms) {
      p.b_access_all_farms = true;
    }

    for (const f of data.details.assigned_farms) {
      p[`farm ${f.id.toString()}`] = true;
      if (f.b_access_all_fields) {
        p[`b_access_all_fields ${f.id.toString()}`] = true;
      }
    }

    for (const f of data.details.assigned_fields) {
      p[`field ${f.id.toString()}`] = true;
      if (f.b_access_all_scout_points) {
        p[`b_access_all_scout_points ${f.id.toString()}`] = true;
      }
    }

    for (const f of data.details.assigned_scout_points) {
      p[`point ${f.id.toString()}`] = true;
      if (f.scout_date.length) {
        p[`point share ${f.id.toString()}`] = true;
        let dateParts = f.scout_date.split("/");
        p[`point date ${f.id.toString()}`] = new Date(
          +dateParts[2],
          dateParts[1] - 1,
          +dateParts[0]
        );
      }
    }
    return (
      <Formik
        initialValues={p}
        //validationSchema={validationSchema}
        onSubmit={(values, { setFieldError, setSubmitting }) => {
          handleSubmit(values, setFieldError, setSubmitting);
          //console.log(values)
        }}
        render={({ isValid, isSubmitting, values, handleSubmit }) => (
          <React.Fragment>
            <Form className="UsersUpdatePermissions">
              <div className="FormBox">
                <div className="RememberForgot">
                  <div className="Checkbox">
                    <Field
                      name={"b_access_all_farms"}
                      type="checkbox"
                      id={"b_access_all_farms"}
                      checked={values.b_access_all_farms}
                    />
                    <label
                      className="CustomInput"
                      htmlFor={"b_access_all_farms"}
                    >
                      Access all farms
                    </label>
                  </div>
                </div>
              </div>
              {data.details.permissions.map((p: any, i: number) => {
                if (values.b_access_all_farms) {
                  return null;
                }
                return (
                  <div className={`FormBox`} key={"farm" + i}>
                    <div className="RememberForgot">
                      <div className="Checkbox FirstLayer">
                        <Field
                          name={"farm " + p.id.toString()}
                          type="checkbox"
                          id={"farm " + p.id.toString()}
                          checked={values[`farm ${p.id.toString()}`]}
                        />
                        <label
                          className="CustomInput"
                          htmlFor={"farm " + p.id.toString()}
                        >
                          {p.farm_name}
                        </label>
                      </div>
                      {p.fields &&
                        Array.isArray(p.fields) &&
                        p.fields
                          .filter(
                            (field: any) =>
                              field !== undefined &&
                              field !== null &&
                              field.id !== null
                          )
                          .map((f: any, x: number) => {
                            return (
                              <React.Fragment key={`farm ${i} field ${x}`}>
                                {x === 0 && (
                                  <div className={`FormBox`}>
                                    <div className="RememberForgot">
                                      {values["farm " + p.id.toString()] ? (
                                        <div className={`Checkbox SecondLayer`}>
                                          <Field
                                            name={
                                              "b_access_all_fields " +
                                              p.id.toString()
                                            }
                                            type="checkbox"
                                            id={
                                              "b_access_all_fields " +
                                              p.id.toString()
                                            }
                                            checked={
                                              values[
                                                `b_access_all_fields ${p.id.toString()}`
                                              ]
                                            }
                                          />
                                          <label
                                            className="CustomInput"
                                            htmlFor={
                                              "b_access_all_fields " +
                                              p.id.toString()
                                            }
                                          >
                                            Access all fields
                                          </label>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                )}
                                <div>
                                  {values["farm " + p.id.toString()] &&
                                  !values[
                                    "b_access_all_fields " + p.id.toString()
                                  ] ? (
                                    <div
                                      key={"farm" + i + "field" + x}
                                      className={`Checkbox SecondLayer`}
                                    >
                                      <Field
                                        name={"field " + f.id.toString()}
                                        type="checkbox"
                                        id={"field " + f.id.toString()}
                                        checked={
                                          values[`field ${f.id.toString()}`]
                                        }
                                      />
                                      <label
                                        className="CustomInput"
                                        htmlFor={"field " + f.id.toString()}
                                      >
                                        {f.field_name}
                                      </label>
                                    </div>
                                  ) : null}
                                  {f.scout_points &&
                                    Array.isArray(f.scout_points) &&
                                    f.scout_points
                                      .filter(
                                        (scoutPoint: any) =>
                                          scoutPoint !== undefined &&
                                          scoutPoint !== null &&
                                          scoutPoint.id !== null
                                      )
                                      .map((s: any, n: number) => {
                                        return (
                                          <React.Fragment
                                            key={
                                              "farm" +
                                              i +
                                              "field" +
                                              x +
                                              "point" +
                                              n
                                            }
                                          >
                                            {n === 0 && (
                                              <div className="FormBox">
                                                <div className="RememberForgot">
                                                  {values[
                                                    "farm " + p.id.toString()
                                                  ] &&
                                                  values[
                                                    "field " + f.id.toString()
                                                  ] &&
                                                  !values[
                                                    "b_access_all_fields " +
                                                      p.id.toString()
                                                  ] ? (
                                                    <div
                                                      className={`Checkbox ThirdLayer`}
                                                    >
                                                      <Field
                                                        name={
                                                          "b_access_all_scout_points " +
                                                          f.id.toString()
                                                        }
                                                        type="checkbox"
                                                        id={
                                                          "b_access_all_scout_points " +
                                                          f.id.toString()
                                                        }
                                                        checked={
                                                          values[
                                                            `b_access_all_scout_points ${f.id.toString()}`
                                                          ]
                                                        }
                                                      />
                                                      <label
                                                        className="CustomInput"
                                                        htmlFor={
                                                          "b_access_all_scout_points " +
                                                          f.id.toString()
                                                        }
                                                      >
                                                        Access all scout points
                                                      </label>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            )}
                                            {values[
                                              "farm " + p.id.toString()
                                            ] &&
                                            values[
                                              "field " + f.id.toString()
                                            ] &&
                                            !values[
                                              "b_access_all_fields " +
                                                p.id.toString()
                                            ] &&
                                            !values[
                                              "b_access_all_scout_points " +
                                                f.id.toString()
                                            ] ? (
                                              <div
                                                className={`Checkbox ThirdLayer`}
                                              >
                                                <Field
                                                  name={
                                                    "point " + s.id.toString()
                                                  }
                                                  type="checkbox"
                                                  id={
                                                    "point " + s.id.toString()
                                                  }
                                                  checked={
                                                    values[
                                                      `point ${s.id.toString()}`
                                                    ]
                                                  }
                                                />
                                                <label
                                                  className="CustomInput"
                                                  htmlFor={
                                                    "point " + s.id.toString()
                                                  }
                                                >
                                                  {s.scout_point}
                                                </label>
                                              </div>
                                            ) : null}
                                            {values[
                                              "farm " + p.id.toString()
                                            ] &&
                                            values[
                                              "field " + f.id.toString()
                                            ] &&
                                            !values[
                                              "b_access_all_fields " +
                                                p.id.toString()
                                            ] &&
                                            !values[
                                              "b_access_all_scout_points " +
                                                f.id.toString()
                                            ] &&
                                            values[
                                              "point " + s.id.toString()
                                            ] ? (
                                              <div
                                                className={`Checkbox FourthLayer`}
                                              >
                                                <div>
                                                  <Field
                                                    name={
                                                      "point share " +
                                                      s.id.toString()
                                                    }
                                                    type="checkbox"
                                                    id={
                                                      "point share " +
                                                      s.id.toString()
                                                    }
                                                    checked={
                                                      values[
                                                        `point share ${s.id.toString()}`
                                                      ]
                                                    }
                                                  />
                                                  <label
                                                    className="CustomInput MarginBottom"
                                                    htmlFor={
                                                      "point share " +
                                                      s.id.toString()
                                                    }
                                                  >
                                                    Share for a day
                                                  </label>
                                                </div>
                                                {values[
                                                  "farm " + p.id.toString()
                                                ] &&
                                                values[
                                                  "field " + f.id.toString()
                                                ] &&
                                                !values[
                                                  "b_access_all_fields " +
                                                    p.id.toString()
                                                ] &&
                                                !values[
                                                  "b_access_all_scout_points " +
                                                    f.id.toString()
                                                ] &&
                                                values[
                                                  "point " + s.id.toString()
                                                ] &&
                                                values[
                                                  "point share " +
                                                    s.id.toString()
                                                ] ? (
                                                  <div className={`Checkbox`}>
                                                    <CalendarDatePickerField
                                                      name={
                                                        "point date " +
                                                        s.id.toString()
                                                      }
                                                      placeholder="Share"
                                                      inputPlaceholderText="DD/MM/YYYY"
                                                      excludeDay={false}
                                                    />
                                                  </div>
                                                ) : null}
                                              </div>
                                            ) : null}
                                          </React.Fragment>
                                        );
                                      })}
                                </div>
                              </React.Fragment>
                            );
                          })}
                    </div>
                  </div>
                );
              })}
              {
                <ErrorMessage name="permissions">
                  {message => (
                    <span className="ErrorMessageLeft">{message}</span>
                  )}
                </ErrorMessage>
              }
            </Form>
            <div className="Bottom">
              <button
                className="Btn BtnWhite"
                onClick={() => dispatch({ type: "CLOSE_RIGHT_POP_OUT" })}
              >
                Cancel
              </button>
              <button
                className="Btn"
                type="submit"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <Spinner
                    name="three-bounce"
                    className="BtnSpinner"
                    color="white"
                    fadeIn="none"
                  />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </React.Fragment>
        )}
      />
    );
  }

  return <></>;
};

export default UsersUpdatePermissions;
