import React, { useState } from "react";
import NewUserAddForm from "../Forms/NewUserAddForm/NewUserAddForm";
import { useFetch } from "../../helpers/loading";
import { endpoints } from "../../serverDetails";
import axios from "api";
import Spinner from "react-spinkit";
import { useDispatch, useSelector } from "react-redux";
import { dateObjectToString } from "../../helpers/dates";
import { UserTypeSelect } from "./UserTypeSelect";
import { Link } from "@reach/router";
const errorResults = "/content/images/Icon_Error.png";

export interface UsersAdd {
  class?: string;
}

const UsersAdd = (props: UsersAdd) => {
  const dispatch = useDispatch();
  const [sharingUser, setSharingUser] = useState<boolean | null>(null);
  const [generalError, setGeneralError] = useState<string>("");
  const clientType = useSelector((state: any) => state.auth.client_type);
  const canAddAccountUsers = useSelector(
    (state: any) => state.auth.b_can_add_account_users
  );
  const handleToast = (message: string) => {
    dispatch({ type: "SHOW_TOAST", message: message });
    window.setTimeout(() => {
      dispatch({ type: "HIDE_TOAST" });
    }, 3000);
  };

  const parseError = (submitError: string) => {
    switch (submitError) {
      case "Account_In_Use":
        return "Account already in use, please use a different email address";
      case "Invalid_Email_Address":
        return "Invalid email address";
      case "Invalid_Telephone_Number":
        return "Invalid telephone number";
      case "Company_Already_Exist":
        return "This company name is already in use";
      case "Total_Users_Would_Exceed_Allowance":
        return "Current number of users exceeds the allowance for this subscription tier";
      default:
        return "Something went wrong, please refresh and try again";
    }
  };

  const handleSubmit = async (
    payload: any,
    setFieldError: (field: string, val: string) => void,
    setSubmitting: any
  ) => {
    if (payload) {
      let permissionValues = Object.keys(payload);

      let postObject = {
        title: payload.title,
        company_name: payload.company_name,
        first_name: payload.first_name,
        last_name: payload.last_name,
        email_address: payload.email_address,
        telephone: payload.telephone,
        mobile: payload.mobile,
        b_suppressed: payload.b_suppressed,
        b_access_all_farms: payload.b_access_all_farms,
        b_enterprise_user: sharingUser ? false : true,
        permissions: payload.b_access_all_farms
          ? []
          : //eslint-disable-next-line
            payload.permissions
              .map((p: any) => {
                let touched = permissionValues.find(v => v === `farm ${p.id}`);
                if (touched && payload[touched]) {
                  let fieldAccess = permissionValues.find(
                    v => v === `b_access_all_fields ${p.id}`
                  );
                  return {
                    id: p.id,
                    b_access_all_fields:
                      fieldAccess && payload[fieldAccess] ? true : false,
                    fields:
                      fieldAccess && payload[fieldAccess]
                        ? []
                        : //eslint-disable-next-line
                          p.fields
                            .map((f: any) => {
                              let fieldTouched = permissionValues.find(
                                v => v === `field ${f.id}`
                              );
                              if (fieldTouched && payload[fieldTouched]) {
                                let pointAccess = permissionValues.find(
                                  v => v === `b_access_all_scout_points ${f.id}`
                                );
                                return {
                                  id: f.id,
                                  b_access_all_scout_points:
                                    pointAccess && payload[pointAccess]
                                      ? true
                                      : false,
                                  scout_points:
                                    pointAccess && payload[pointAccess]
                                      ? []
                                      : //eslint-disable-next-line
                                        f.scout_points
                                          .map((s: any) => {
                                            let scoutAccess = permissionValues.find(
                                              v => v === `point ${s.id}`
                                            );
                                            if (
                                              scoutAccess &&
                                              payload[scoutAccess]
                                            ) {
                                              let scoutTouched = permissionValues.find(
                                                v => v === `point share ${s.id}`
                                              );
                                              if (
                                                scoutTouched &&
                                                payload[scoutTouched]
                                              ) {
                                                let pointDate = permissionValues.find(
                                                  v =>
                                                    v === `point date ${s.id}`
                                                );
                                                return {
                                                  id: s.id,
                                                  scout_date: pointDate
                                                    ? dateObjectToString(
                                                        payload[pointDate]
                                                      )
                                                    : dateObjectToString(
                                                        new Date()
                                                      ),
                                                };
                                              } else {
                                                return {
                                                  id: s.id,
                                                  scout_date: "",
                                                };
                                              }
                                            }
                                          })
                                          .filter((i: any) => i),
                                };
                              }
                            })
                            .filter((i: any) => i),
                  };
                }
              })
              .filter((i: any) => i),
      };

      if (
        postObject.permissions.length === 0 &&
        !postObject.b_access_all_farms &&
        sharingUser
      ) {
        setFieldError("permissions", "Please select some permissions");
        setSubmitting(false);
      } else {
        setSubmitting(true);
        axios
          .post(endpoints.users.createUsers, postObject)
          .then(res => {
            if (res.data.status === "1") {
              setSubmitting(false);
              dispatch({ type: "CLOSE_RIGHT_POP_OUT" });
              handleToast("User successfully added");
              dispatch({
                type: "REFRESH_DATA_SHARING_LIST",
              });
            }
          })
          .catch(err => {
            if (
              err.response &&
              err.response.data &&
              err.response.data.errors.length > 0
            ) {
              if (err.response.data.errors[0].field_name === "Error_Label") {
                setGeneralError(
                  parseError(err.response.data.errors[0].message_code)
                );
              }
              setFieldError(
                err.response.data.errors[0].field_name,
                parseError(err.response.data.errors[0].message_code)
              );
              setSubmitting(false);
            }
          });
      }
    }
  };

  const { data, loading, error } = useFetch(endpoints.users.createDetailsUsers);

  if (loading) {
    return (
      <span>
        <Spinner name="three-bounce" fadeIn="none" />
      </span>
    );
  }

  if (error)
    return (
      <div className="ErrorResults">
        <div className="NoResults">
          <img src={errorResults} alt={"Error"} />
          <p>Error</p>
        </div>
      </div>
    );

  if (data) {
    return (
      <div className={`UsersAdd ${props.class || ""}`}>
        <UserTypeSelect
          setSharingUser={setSharingUser}
          active={sharingUser === false}
          name="Account user"
          disabled={!canAddAccountUsers && clientType !== "Enterprise"}
        />
        {!canAddAccountUsers && clientType !== "Enterprise" && (
          <div className="UsersAdd__upgradeNotice">
            You need to{" "}
            <Link
              onClick={() => {
                dispatch({ type: "CLOSE_RIGHT_POP_OUT" });
              }}
              to="/"
            >
              upgrade your account
            </Link>{" "}
            to add more account users.
          </div>
        )}
        <UserTypeSelect
          setSharingUser={setSharingUser}
          active={sharingUser === true}
          name="Sharing user"
        />

        <NewUserAddForm
          submit={(
            values: any,
            setFieldError: (field: string, val: string) => void,
            setSubmitting: (val: boolean) => void
          ) => handleSubmit(values, setFieldError, setSubmitting)}
          //submit={(values) => handleSubmit(values)}
          data={data}
          sharingUser={sharingUser}
        />
        <div className="UsersAdd__generalError">{generalError}</div>
      </div>
    );
  }

  return (
    <div className={`UsersAdd ${props.class || ""}`}>
      <UserTypeSelect
        setSharingUser={setSharingUser}
        active={sharingUser === false}
        name="Account user"
        disabled={!canAddAccountUsers && clientType !== "Enterprise"}
      />
      {!canAddAccountUsers && clientType !== "Enterprise" && (
        <div className="UsersAdd__upgradeNotice">
          You need to{" "}
          <Link
            onClick={() => {
              dispatch({ type: "CLOSE_RIGHT_POP_OUT" });
            }}
            to="/"
          >
            upgrade your account
          </Link>{" "}
          to add more account users.
        </div>
      )}
      <UserTypeSelect
        setSharingUser={setSharingUser}
        active={sharingUser === true}
        name="Sharing user"
      />

      <NewUserAddForm
        submit={(
          values: any,
          setFieldError: (field: string, val: string) => void,
          setSubmitting: (val: boolean) => void
        ) => handleSubmit(values, setFieldError, setSubmitting)}
        sharingUser={sharingUser}
      />
      <div className="UsersAdd__generalError">{generalError}</div>
    </div>
  );
};

export default UsersAdd;
